<template>
    <Page>
        <TitleBar :title="'Shopify'" :style="{ color: '#33393F' }" :is-read-only="isReadOnly" >

         
        </TitleBar>
        <ul class="tab-header">
                <li v-for="(tab, index) in tabs" :key="index" :class="{ active: selectedTab === index }"
                    @click="selectTab(index)">
                    {{ tab.label }}
                </li>
            </ul>
        <div>



            

            <!-- Displaying the content of the selected tab -->

            <div class="container-fluid">
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4">

                        <div class="tab-content">
                            <component :is="currentTabComponent" />
                        </div>
                    </div>
                    <div class="col-4"></div>
                </div>



            </div>
        </div>
    </Page>
</template>

<script>
import AppConfigTab from '~/modules/@ergonode/app/src/components/tabs/AppConfigTab.vue';
import AppHistoryTab from '~/modules/@ergonode/app/src/components/tabs/AppHisotryTab.vue';
import AppOptionTab from '~/modules/@ergonode/app/src/components/tabs/AppOptionTab.vue';
import AppSchedulerTab from '~/modules/@ergonode/app/src/components/tabs/AppSchedulerTab.vue';

export default {
    data() {
        return {
            selectedTab: 0, // Default selected tab
            tabs: [
                { label: 'Options', component: AppOptionTab },
                { label: 'Configuration', component: AppConfigTab },
                { label: 'History', component: AppHistoryTab },
                { label: 'Scheduler', component: AppSchedulerTab }
            ],


        };
    },
    computed: {
        // Dynamically load the component based on the selected tab
        currentTabComponent() {
            return this.tabs[this.selectedTab].component;
        },
    },
    methods: {
        selectTab(index) {
            this.selectedTab = index;
        },
    },
    mounted() {
        console.log(this.$t('@App.Shopify._.headTitle'));
    }

};
</script>

<style scoped lang="scss">
// Variables for reusable colors and sizes
$white: #fff;
$gray-light: #f9f9f9;
$gray-dark: #33393F;
$border-color: #ddd;
$hover-bg: #f1f1f1;
$active-color: $gray-dark;
$tab-padding: 10px 20px;
$tab-hover-padding: 15px 0;
$box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

.tab-header {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    background-color: $white;
    border-bottom: 2px solid $border-color;
  
    li {
        cursor: pointer;
        flex: 1;
        padding: $tab-padding;
        text-align: center;
        border-bottom: 2px solid transparent;
        transition: all 0.3s ease-in-out;

        &.active {
            border-bottom: 2px solid $active-color;
            color: $active-color;
        }

        &:hover {
            background-color: $hover-bg;
            border-bottom: 2px solid $active-color;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
    
        li {
            flex: none;
            padding: $tab-hover-padding;
        }
    }
}

.tab-content {
    padding: 20px;
    background-color: $gray-light;
    border: 1px solid $border-color;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: $box-shadow;

    @media (max-width: 576px) {
        padding: 15px;
    }
}

// Layout adjustments
.container-fluid {
    margin: 0;
    padding: 0;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.col-4 {
    flex: 0 0 33.33%;
    padding: 0 15px;

    @media (max-width: 768px) {
        flex: 0 0 100%;
    }
}

</style>
