var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Page",
    [
      _c("TitleBar", {
        style: { color: "#33393F" },
        attrs: { title: "Shopify", "is-read-only": _vm.isReadOnly }
      }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "tab-header" },
        _vm._l(_vm.tabs, function(tab, index) {
          return _c(
            "li",
            {
              key: index,
              class: { active: _vm.selectedTab === index },
              on: {
                click: function($event) {
                  return _vm.selectTab(index)
                }
              }
            },
            [
              _vm._v(
                "\n                " + _vm._s(tab.label) + "\n            "
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _c(
                "div",
                { staticClass: "tab-content" },
                [_c(_vm.currentTabComponent, { tag: "component" })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" })
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }